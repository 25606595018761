<template>
  <div>
    <div class="d-flex align-items-center" style="background-color:#041429; min-height:300px">
      <div>
        <b-row>
          <b-col>
            <p class="TextWhite32" style="text-align:left;margin-left:10%">{{$t('uzbekFamous')}}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p class="TextWhite17" style="text-align:left;margin-left:20%">
          <!-- «O‘zbekiston arboblari» veb-sayti O‘zbekiston taraqqiyotiga hissa
          qo‘shgan taniqli insonlar haqida ma’lumot beradi. Ushbu sayt
          ilmiy-ommabop, ma’rifiy va ta’limiy resursdir. -->
          {{$t('uzbekFamousInfo')}}
        </p>
          </b-col>
          <b-col>

          </b-col>
        </b-row>
      </div>
    </div>
    <div class="text-center mt-3 mb-3">
      <div>
        <p class="infoTextB text-left" style="margin-left:20%" >
         {{$t('famousPeople')}}
        </p>
      </div>
      
      <div class="famousCardPosition" style="margin-left:15%;margin-right:15%">
        <b-row class="m-4">
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
              @click="$router.push({ name: 'abdulla_avloniy' })"
                class="famouscard m-3 "
              >
                <template>
                  <b-img
                    class="image"
                    style="width:40%"
                    :src="require('../../assets/Abdulla_Avloniy1.jpg')"
                  ></b-img>
                </template>
               <p class="school32 text-center mb-0 pb-0" >{{ $t("Абдулла Авлоний") }}</p>
               <p class="TextBlack17 text-center mt-0 pt-0">(1878-1917 )</p>
              </b-card>
            </b-card-group>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
                class="famouscard m-3"
                @click="$router.push({ name: 'abdulvohid_burhonov' })"
              >
    
                <template>
                  <b-img
                    class="image"
                    style="width:40%"
                    :src="require('../../assets/user_1.png')"
                  ></b-img>
                </template>
                <b-card-text
                  ><p class="school32 text-center mb-0 pb-0">
                      {{ $t("Aбдулвоҳид Бурҳонов - Мунзим ") }}
                    </p>
                    <p class="TextBlack17 text-center mt-0 pt-0">(1875-1934)</p></b-card-text
                >
              </b-card>
            </b-card-group>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
              @click="$router.push({ name: 'abduqodir_shakuriy' })"
                class="famouscard m-3 "
              >
                <template>
                  <b-img
                    class="image"
                    style="width:32%"
                    :src="require('@/assets/Абдукадыр_Шакури.jpg')"
                  ></b-img>
                </template>
                <b-card-text
                  > <p class="school32 text-center mb-0 pb-0">{{ $t("Абдуқодир Шакурий") }}</p><p class="TextBlack17 text-center mt-0 pt-0">
                   (1875-1943)
                  </p></b-card-text
                >
              </b-card>
            </b-card-group>
          </b-col>
        <!-- </b-row>
        <b-row class="m-4"> -->
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
              @click="$router.push({ name: 'majid_qori_qodiriy' })"
                class="famouscard m-3 "
              >
                <template>
                  <b-img
                    class="image"
                    style="width:32%"
                    :src="require('../../assets/abdulmajid_qodiriy.jpg')"
                  ></b-img>
                </template>
                <b-card-text
                  ><p class="school32 text-center mb-0 pb-0">{{ $t("Aбдулмажид Қодирий ") }}</p><p class="TextBlack17 text-center mt-0 pt-0">
                  (1886 -1938 )
                  </p></b-card-text
                >
              </b-card>
            </b-card-group>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
               @click="$router.push({ name: 'abdulqodir_muhiddinov' })"
               class="famouscard m-3 "
              >
                <template>
                  <b-img
                    class="image"
                    style="width:40%"
                    :src="require('../../assets/user_1.png')"
                  ></b-img>
                </template>
                <b-card-text
                  > <p class="school32 text-center mb-0 pb-0">{{ $t("Муҳитдинов Aбдулқодир") }}</p><p class="TextBlack17 text-center mt-0 pt-0">
                   (1892-1934)
                  </p></b-card-text
                >
                
              </b-card>
            </b-card-group>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
              @click="$router.push({ name: 'ajziy' })"
                class="famouscard m-3 "
              >
                <template>
                  <b-img
                    class="image"
                    style="width:40%"
                    :src="require('@/assets/user_1.png')"
                  ></b-img>
                </template>
                <b-card-text
                  > <p class="school32 text-center mb-0 pb-0">{{ $t("Ажзий ") }}</p><p class="TextBlack17 text-center mt-0 pt-0">
                    (1864 -1927 )
                  </p></b-card-text
                >
                
              </b-card>
            </b-card-group>
          </b-col>
        <!-- </b-row>
        <b-row class="m-4"> -->
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
              @click="$router.push({ name: 'bobooxun_salimov' })"
                class="famouscard m-3 "
              >
                <template>
                  <b-img
                    class="image"
                    style="width:32%"
                    :src="require('../../assets/bobooxun-salimov.jpg')"
                  ></b-img>
                </template>
                <b-card-text 
                  >  <p class="school32 text-center mb-0 pb-0">{{ $t("Бобоохун Салимов ") }}</p><p class="TextBlack17 text-center mt-0 pt-0">
                    (1874-1929)
                  </p></b-card-text
                >
              </b-card>
            </b-card-group>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card @click="$router.push({ name: 'boltihoji_sultonov' })"
                class="famouscard m-3 "
              >
                <template>
                  <b-img
                    class="image"
                    style="width:40%"
                    :src="require('../../assets/user_1.png')"
                  ></b-img>
                </template>
                <b-card-text
                  > <p class="school32 text-center mb-0 pb-0">
                      {{ $t("Султонов Болтиҳожи Султонович") }}
                    </p><p class="TextBlack17 text-center mt-0 pt-0">
                    (1884-1919)
                  </p></b-card-text
                >
              </b-card>
            </b-card-group>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
               @click="$router.push({ name: 'fayzulla_xojayev' })"
                class="famouscard m-3 "
              >
                <template>
                  <b-img
                    class="image"
                    style="width:32%"
                    :src="require('@/assets/FU_Xojayev.jpg')"
                  ></b-img>
                </template>
                <b-card-text
                  ><p class="school32 text-center mb-0 pb-0">{{ $t("Хўжаев Файзулла Губайдуллаевич ") }}</p>
                  <p class="TextBlack17 text-center mt-0 pt-0">(****-****)</p></b-card-text
                >
              </b-card>
            </b-card-group>
          </b-col>
        <!-- </b-row>
        <b-row class="m-4"> -->
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
              @click="$router.push({ name: 'fitrat' })"
                class="famouscard m-3 "
              >
                <template>
                  <b-img
                    class="image"
                    style="width:29%"
                    :src="require('../../assets/Fitrat.jpg')"
                  ></b-img>
                </template>
                <b-card-text 
                  > <p class="school32 text-center mb-0 pb-0">{{ $t("Фитрат") }}</p><p class="TextBlack17 text-center mt-0 pt-0">
                    (1884 -1938 )
                  </p></b-card-text
                >
              </b-card>
            </b-card-group>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
               @click="$router.push({ name: 'fozilbek_qosimbekov' })"
               class="famouscard m-3 "
              >
                <template>
                  <b-img
                    class="image"
                    style="width:40%"
                    :src="require('../../assets/user_1.png')"
                  ></b-img>
                </template>
                <b-card-text
                  > <p class="school32 text-center mb-0 pb-0">{{ $t("Фозилҳўжа Қосимбеков") }}</p><p class="TextBlack17 text-center mt-0 pt-0">
                   (1875-1934)
                  </p></b-card-text
                >
               
              </b-card>
            </b-card-group>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
              @click="$router.push({ name: 'hamza' })"
               class="famouscard m-3 "
              >
                
                <template>
                  <b-img
                    class="image"
                    style="width:31%"
                    :src="require('@/assets/hamza.jpg')"
                  ></b-img>
                </template>
                <b-card-text
                  > <p class="school32 text-center mb-0 pb-0">{{ $t("Ҳамза Ҳакимзода Ниёзий") }}</p><p class="TextBlack17 text-center mt-0 pt-0">
                    (1889-1929)
                  </p></b-card-text
                >
               
              </b-card>
            </b-card-group>
          </b-col>
        <!-- </b-row>
        <b-row class="m-4"> -->
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
              @click="$router.push({ name: 'ishoq' })"
                class="famouscard m-3 "
              >
                <template>
                  <b-img
                    class="image"
                    style="width:40%"
                    :src="require('../../assets/user_1.png')"
                  ></b-img>
                </template>
                <b-card-text class="mt-3 "
                  > <p class="school32 text-center mb-0 pb-0">{{ $t("Исъҳоқхон Ибрат") }}</p><p class="TextBlack17 text-center mt-0 pt-0">
                   (1862-1937)
                  </p></b-card-text
                >
              </b-card>
            </b-card-group>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
               @click="$router.push({ name: 'mahmudxoja_behbudiy' })"
               class="famouscard m-3 "
              >
                
                <template>
                  <b-img
                    class="image"
                    style="width:38%"
                    :src="require('../../assets/behbudiy.jpg')"
                  ></b-img>
                </template>
                <b-card-text
                  >
                  <p class="school32 text-center mb-0 pb-0">{{ $t("Маҳмудхўжа Беҳбудий") }}</p><p class="TextBlack17 text-center mt-0 pt-0">
                   (1875 -1919 )
                  </p></b-card-text
                >
                
              </b-card>
            </b-card-group>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
               @click="$router.push({ name: 'muhammad_sharif' })"
               class="famouscard m-3 "
              >
               
                <template>
                  <b-img
                    class="image"
                    style="width:35%"
                    :src="require('../../assets/muhammadsharif-sufizoda.jpg')"
                  ></b-img>
                </template>
                <b-card-text 
                  >
                   <p class="school32 text-center mb-0 pb-0">{{ $t("Муҳаммадшариф Сўфизода") }}</p>
                   <p class="TextBlack17 text-center mt-0 pt-0">
                    (1880–1937)
                  </p></b-card-text
                >
              </b-card>
            </b-card-group>
          </b-col>
        <!-- </b-row>
        <b-row class="m-4"> -->
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
               @click="$router.push({ name: 'munavvarqori_abdurashid' })"
               class="famouscard m-3 "
              >
               
                <template>
                  <b-img
                    class="image"
                    style="width:35%"
                    :src="require('../../assets/munavvarqori.jpg')"
                  ></b-img>
                </template>
                <b-card-text
                  ><p class="school32 text-center mb-0 pb-0">
                   {{ $t("Мунавварқори Абдурашидхонов") }}
                  </p>
                  <p class="TextBlack17 text-center mt-0 pt-0">(1878 -1931 )</p></b-card-text
                >
               
              </b-card>
            </b-card-group>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
               @click="$router.push({ name: 'obid_mahmudov' })"
                class="famouscard m-3 "
              >
               
                <template>
                  <b-img
                    class="image"
                    style="width:40%"
                    :src="require('@/assets/user_1.png')"
                  ></b-img>
                </template>
                <b-card-text
                  ><p class="school32 text-center mb-0 pb-0">
                   {{ $t("Обиджон Маҳмудов ") }}
                  </p>
                  <p class="TextBlack17 text-center mt-0 pt-0">(1871-1936)</p></b-card-text
                >
               
              </b-card>
            </b-card-group>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
              @click="$router.push({ name: 'rahmanberdi_madazimov' })"
                class="famouscard m-3 "
              >
                <template>
                  <b-img
                    class="image"
                    style="width:40%"
                    :src="require('../../assets/user_1.png')"
                  ></b-img>
                </template>
                <b-card-text 
                  ><p class="school32 text-center mb-0 pb-0">
                   {{ $t("Раҳмонберди Мадазимов") }}
                  </p>
                  <p class="TextBlack17 text-center mt-0 pt-0">(1875–1933)</p></b-card-text
                >
              </b-card>
            </b-card-group>
          </b-col>
        <!-- </b-row>
        <b-row class="m-4"> -->
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
               @click="$router.push({ name: 'polvonniyoz_hoji' })"
               class="famouscard m-3 "
              >
               
                <template>
                  <b-img
                    class="image"
                    style="width:40%"
                    :src="require('@/assets/user_1.png')"
                  ></b-img>
                </template>
                <b-card-text
                  ><p class="school32 text-center mb-0 pb-0">
                   {{ $t("Полвонниёз ҳожи Юсупов ") }}<br />
                  </p>
                  <p class="TextBlack17 text-center mt-0 pt-0">(1861-1936)</p></b-card-text
                >
                
              </b-card>
            </b-card-group>
          </b-col>

          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
              @click="$router.push({ name: 'sadriddin_ayniy' })"
                class="famouscard m-3 "
              >
                
                <template>
                  <b-img
                    class="image"
                    style="width:30%"
                    :src="require('../../assets/saniy.png')"
                  ></b-img>
                </template>
                <b-card-text
                  ><p class="school32 text-center mb-0 pb-0">
                   {{ $t("Садриддин Aйний") }}
                  </p>
                  <p class="TextBlack17 text-center mt-0 pt-0">(1878 -1954)</p></b-card-text
                >
              </b-card>
            </b-card-group>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
              @click="$router.push({ name: 'toshpolatbek' })"
                class="famouscard m-3 "
              >
               
                <template>
                  <b-img
                    class="image"
                    style="width:40%"
                    :src="require('@/assets/user_1.png')"
                  ></b-img>
                </template>
                <b-card-text
                  ><p class="school32 text-center mb-0 pb-0">
                  {{ $t("Тошпулат Норбутабеков") }}
                  </p>
                  <p class="TextBlack17 text-center mt-0 pt-0">(1898 - ---)</p></b-card-text
                >
              </b-card>
            </b-card-group>
          </b-col>
        <!-- </b-row>
        <b-row class="m-4"> -->
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
              @click="$router.push({ name: 'ubaydullaxoja' })"
               class="famouscard m-3 "
              >
                
                <template>
                  <b-img
                    class="image"
                    style="width:40%"
                    :src="require('../../assets/user_1.png')"
                  ></b-img>
                </template>
                <b-card-text class="mt-3 "
                  ><p class="school32 text-center mb-0 pb-0">
                     {{ $t("Убайдуллахўжа Асaдуллaхўжaев ") }}
                  </p>
                  <p class="TextBlack17 text-center mt-0 pt-0">(1878-1937)</p></b-card-text
                >
              </b-card>
            </b-card-group>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
              @click="$router.push({ name: 'usmonxoja' })"
              class="famouscard m-3 "
              >
                <template>
                  <b-img
                    class="image"
                    style="width:35%"
                    :src="require('../../assets/usmon.jpg')"
                  ></b-img>
                </template>
                <b-card-text
                  ><p class="school32 text-center mb-0 pb-0">{{ $t("Усмон хўжа") }}</p>
                  <p class="TextBlack17 text-center mt-0 pt-0">(1878-1968)</p></b-card-text
                >
              </b-card>
            </b-card-group>
          </b-col>
          <b-col sm="12" md="6" lg="4">
            <b-card-group deck>
              <b-card
                class="famouscard m-3 "
                @click="$router.push({ name: 'cholpon' })"
              >
                <template>
                  <b-img
                    class="image"
                    style="width:35%"
                    :src="require('@/assets/chulpon.jpg')"
                  ></b-img>
                </template>
                <b-card-text
                  > <p class="school32 text-center mb-0 pb-0">{{ $t("Чўлпон") }}</p>
                  <p class="TextBlack17 text-center mt-0 pt-0">(1897-1938)</p></b-card-text
                >
              </b-card>
            </b-card-group>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style>
.image {
  border-radius: 50%;
  padding: 1px;
}
.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}
.a:hover {
  text-decoration: underline;
  color: blue;
}
</style>
